<template>
    <div id="app">
        <div id="coverBlack" @click="hidBlack()">
            <img src="" alt="" id="coverMainImg">
        </div>
        <div id="coverBlack2" @click="hidBlack2()">
            <div class="cover_inp_box" @click="eCoverBlack2($event)">
                <img class="cover_inp_left" src="./img/coicon3.png" alt="">
                <div @click="hidBlack2()" class="hidCha">
                    <p class="p3"></p>
                    <p class="p4"></p>
                </div>
                <div class="cover_inp_right">
                    <div class="s_inp_right">
                        <el-form :inline="true" :label-position="labelPosition" label-width="1.3rem"
                            :model="formLabelAlign">
                            <el-form-item label="姓名：">
                                <el-input v-model="formLabelAlign.name" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="电话：">
                                <el-input v-model="formLabelAlign.tel" placeholder="请输入电话号码"></el-input>
                            </el-form-item>
                            <el-form-item label="意向专业：">
                                <el-input v-model="formLabelAlign.major" placeholder="请输入意向专业"></el-input>
                            </el-form-item>
                            <el-form-item label="意向国家：" class="elitem2">
                                <el-select v-model="formLabelAlign.region2" placeholder="选择意向国家">
                                    <el-option label="英国" value="England"></el-option>
                                    <el-option label="美国" value="America"></el-option>
                                    <el-option label="日本" value="Japan"></el-option>
                                    <el-option label="新加坡" value="Singapore"></el-option>
                                    <el-option label="韩国" value="Korea"></el-option>
                                    <el-option label="欧洲" value="Europe"></el-option>

                                    <el-option label="其他" value="Other"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <div class="yuyue" @click="onSubmit()">
                                    <img src="./img/coicon2.png" alt="">
                                    <p>立即预约</p>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>

                </div>
            </div>
        </div>

        <div id="coverBlack3" @click="hidBlack3()">
            <div class="cover_inp_box" @click="eCoverBlack3($event)">
                <img class="cover_inp_left" src="./img/coicon4.png" alt="">
                <div @click="hidBlack3()" class="hidCha">
                    <p class="p3"></p>
                    <p class="p4"></p>
                </div>
                <div class="cover_inp_right">
                    <div class="s_inp_right">
                        <el-form :inline="true" :label-position="labelPosition" label-width="" :model="formLabelAlign">
                            <el-form-item label="姓名：">
                                <el-input v-model="formLabelAlign.name" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="电话：">
                                <el-input v-model="formLabelAlign.tel" placeholder="请输入电话号码"></el-input>
                            </el-form-item>
                            <el-form-item label="邮箱：">
                                <el-input v-model="formLabelAlign.email" placeholder="请输入邮箱"></el-input>
                            </el-form-item>

                            <el-form-item>
                                <div class="yuyue" @click="onSubmit()">
                                    <img src="./img/coicon2.png" alt="">
                                    <p>立即领取</p>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>

                </div>
            </div>
        </div>


        <nav>
            <div id="cover" @click="hidCover">
                <div class="cover_cont">
                    <div class="cover_top">
                        <div class="cover_cha">
                            ×
                        </div>
                    </div>

                    <router-link to="" class="cover_nav_item">
                        <p class="cover_cn">首页</p>
                        <p class="cover_en">HOMEPAGE</p>
                    </router-link>

                    <div class="">
                        <router-link class="course_main" to="/gdzpj.html">
                            <p class="cover_cn">艺术课程</p>
                            <p class="cover_en">ART COURSE</p>
                            <div class="course_icon" @click="zhankaiCourse($event)"></div>
                        </router-link>
                        <div class="course_box">
                            <router-link to="/PortfolioInfo/vip.html">VIP院校计划</router-link>
                            <router-link to="/PortfolioInfo/sample.html">专业作品集辅导</router-link>
                            <router-link to="/promoting">海外游学项目</router-link>
                            <router-link to="/PortfolioInfo/btec.html">BTEC艺术课程</router-link>
                            <router-link to="/PortfolioInfo/alevel.html">A-LEVEL课程</router-link>
                            <router-link to="/PortfolioInfo/gagd.html">GA&GD创意课程</router-link>
                            <router-link to="/PortfolioInfo/ap.html">AP课程</router-link>
                            <router-link to="/PortfolioInfo/RossoCollege.html">Rosso College</router-link>

                        </div>

                    </div>
                    <router-link to="/teacher.html" class="cover_nav_item">
                        <p class="cover_cn">师资团队</p>
                        <p class="cover_en">ART TUTOR</p>
                    </router-link>
                    <router-link to="/school-0-0.html" class="cover_nav_item">
                        <p class="cover_cn">名校导航</p>
                        <p class="cover_en">ART SCHOOL</p>
                    </router-link>
                    <router-link to="/major.html" class="cover_nav_item">
                        <p class="cover_cn">艺术专业</p>
                        <p class="cover_en">ART MAJOR</p>
                    </router-link>
                    <router-link to="/new-0.html" class="cover_nav_item">
                        <p class="cover_cn">留学资讯</p>
                        <p class="cover_en">ART NEWS</p>
                    </router-link>
                    <router-link to="/promoting" class="cover_nav_item">
                        <p class="cover_cn">背景提升</p>
                        <p class="cover_en">PROMOTING</p>
                    </router-link>
                    <a class="cover_nav_item" href="http://music.rossoarts.com/" target="_blank">
                        <p class="cover_cn">音乐留学</p>
                        <p class="cover_en">RoSSo MUSIC</p>
                    </a>
                </div>

            </div>
            <div class="nav_left">
                <img src="./img/Group 144.png" alt="" class="nav_logo" @click="goHome">
                <p>洛素<span>国际艺术教育</span></p>
                <div class="icon_nmb_box">
                    <img src="./img/Group 310.png" alt="" class="icon_nmb">
                    <p class="numb">400-0999-530</p>
                </div>
            </div>

            <div class="nav_right">
                <div class="nav_text_box">
                    <router-link to="/">
                        <p class="nav_client_cn">首页</p>
                        <p class="nav_client_en">HOMEPAGE</p>
                    </router-link>
                </div>
                <img src="./img/Group 90.png" alt="" id="show" @click="showCover">
            </div>
        </nav>

        <div class="nav_bottom">
            <router-link to="/gdzpj.html" class="nav_bottom_item">艺术课程</router-link>
            <router-link to="/school-0-0.html" class="nav_bottom_item">名校导航</router-link>
            <router-link to="/major.html" class="nav_bottom_item">艺术专业</router-link>
            <router-link to="/promoting" class="nav_bottom_item">背景提升 </router-link>
        </div>

        <!-- OLD -->
        <!-- <div class="logo_old">
            <img src="./img/oldweb/logo.png" class="logo_old_img">
            <div class="menu" id="menu" @click="selctMenu">
                
                <div class="a"></div>
            </div>
            <div class="nav_list_box" id="nav_box" @click="hidNavList">
                <router-link to="/#" class="cover_nav_item">
                    <p class="cover_cn">首页</p>
                </router-link>
                <router-link class="course_main cover_nav_item" to="/gdzpj.html">
                    <p class="cover_cn">艺术课程</p>

                </router-link>
                <router-link to="/teacher.html" class="cover_nav_item">
                    <p class="cover_cn">师资团队</p>

                </router-link>
                <router-link to="/school-0-0.html" class="cover_nav_item">
                    <p class="cover_cn">名校导航</p>

                </router-link>
                <router-link to="/major.html" class="cover_nav_item">
                    <p class="cover_cn">艺术专业</p>

                </router-link>
                <router-link to="/new-0.html" class="cover_nav_item">
                    <p class="cover_cn">留学资讯</p>

                </router-link>
                <router-link to="/promoting" class="cover_nav_item">
                    <p class="cover_cn">背景提升</p>

                </router-link>
                <a class="cover_nav_item" href="http://music.rossoarts.com/" target="_blank">
                    <p class="cover_cn">音乐留学</p>

                </a>
            </div>
        </div>

        <router-view style="overflow: hidden;" />

        <div class="newFooter">
            <div class="newTop">
                <div class="newTitle">
                    <div class="title">ROSSO 国际艺术教育</div>
                    <div class="text">Fashion&Music Education</div>
                </div>
                <div class="newRight">
                    <img src="./img/oldweb/dianhua.png" alt="">
                    <p>留学咨询：<span>18918340071</span></p>
                </div>
            </div>
            <div class="newBox">
                <div class="newBox_title">音乐留学中心</div>
                <div class="newBox_ul">
                    <div class="newBox_li" @click="toggleLocation(0)"> 上海(总部) </div>
                    <div class="newBox_li" @click="toggleLocation(1)"> 北京 </div>
                    <div class="newBox_li" @click="toggleLocation(2)"> 广州 </div>
                    <div class="newBox_li" @click="toggleLocation(3)"> 成都 </div>
                </div>
                <div class="newBox_juti">
                    <img src="./img/oldweb/location.png" alt="">
                    <p id="newBox_juti">上海徐汇区文定路208号A座</p>
                </div>
                <div class="newBox_title">艺术留学中心</div>
                <div class="newBox_ul">
                    <div class="newBox_li2" @click="toggleLocation2(0)"> 上海(总部) </div>
                    <div class="newBox_li2" @click="toggleLocation2(1)"> 北京 </div>
                    <div class="newBox_li2" @click="toggleLocation2(2)"> 广州 </div>
                    <div class="newBox_li2" @click="toggleLocation2(3)"> 深圳 </div>

                    <div class="newBox_li2" @click="toggleLocation2(4)"> 杭州 </div>
                    <div class="newBox_li2" @click="toggleLocation2(5)"> 南京 </div>
                    <div class="newBox_li2" @click="toggleLocation2(6)"> 成都 </div>
                    <div class="newBox_li2" @click="toggleLocation2(7)"> 武汉 </div>
                    <div class="newBox_li2" @click="toggleLocation2(8)"> 西安 </div>
                    <div class="newBox_li2" @click="toggleLocation2(9)"> 东京 </div>
                </div>
                <div class="newBox_juti">
                    <img src="./img/oldweb/location.png" alt="">
                    <p id="newBox_juti2">上海徐汇区文定路208号A座</p>
                </div>
                <div class="newSa">
                    <p>沪ICP备19007600号-2</p>
                    <p>© 2019-2022 上海洛素文化艺术有限公司 版权所有</p>
                </div>
            </div>
        </div> -->

        <!-- OLD -->
        <router-view style="margin-top:1.34rem;overflow: hidden;" />
        <div class="section_bot">
            <div class="section_box">
                <div class="section_title_cn">RoSSo 国际艺术教育</div>
                <div class="section_title_en">RoSSo Arts & Design Education</div>
            </div>
            <a href="tel:4000999530" @click="call()">
                <img src="./img/Group 280.png" alt="" class="sb_title_img">
            </a>
            <div class="sb_bgc">
                <div class="sb_cont">
                    <div class="sb1">
                        <div class="sb1_title">艺术留学</div>
                        <div class="sb1_cont">
                            <div class="li2" @click="mouse2(0)" style="font-weight: bold;">·上海(总部)</div>
                            <div class="li2" @click="mouse2(1)">·北京总校</div>
                            <div class="li2" @click="mouse2(2)">·北京二校</div>
                            <div class="li2" @click="mouse2(3)">·广州</div>
                            <div class="li2" @click="mouse2(4)">·深圳</div>
                            <div class="li2" @click="mouse2(5)">·杭州</div>
                            <div class="li2" @click="mouse2(6)">·南京</div>
                            <div class="li2" @click="mouse2(7)">·成都</div>
                            <div class="li2" @click="mouse2(8)">·武汉</div>
                            <div class="li2" @click="mouse2(9)">·西安</div>

                            <div class="li2" @click="mouse2(10)">·东京</div>
                        </div>
                        <div class="sb1_location" id="show2">上海市徐汇区文定路208号A座</div>
                    </div>
                    <div class="sb1 sb2">
                        <div class="sb1_title">音乐留学</div>
                        <div class="sb1_cont">
                            <div class="li1" @click="mouse1(0)" style="font-weight: bold;">·上海(总部)</div>
                            <div class="li1" @click="mouse1(1)">·北京</div>
                            <div class="li1" @click="mouse1(2)">·成都</div>
                            <div class="li1" @click="mouse1(3)">·广州</div>
                            <div class="li1" @click="mouse1(4)">·深圳</div>

                        </div>
                        <div class="sb1_location" id="show1">上海市徐汇区文定路208号A座</div>
                    </div>
                    <!-- <div class="sb1 sb2">
                        <div class="sb1_title">合作学校</div>
                        <div class="sb1_cont" style="justify-content: flex-start;">
                            <div class="li3" @click="mouse3(0)" style="font-weight: bold;">·北京</div>
                            <div class="li3" @click="mouse3(1)" style="margin-left: .2rem;">·深圳</div>
                            <div class="li3" @click="mouse3(2)" style="margin-left: .2rem;">·合肥</div>
                            <div class="li3" @click="mouse3(3)" style="margin-left: .2rem;">·内蒙古</div>


                        </div>
                        <div class="sb1_location" id="show3">橘郡国际学校北京校区：北京市海淀区苏州街29号北京八一学校西门</div>
                        <div class="sb1_location" id="show4"
                            style="text-align: left;margin-top: 8px;display: none;font-weight: bold;">
                            橘郡国际学校深圳校区：深圳市宝安区石岩镇育才路8号</div>
                    </div> -->


                    <div class="sb1 sb3">
                        <div class="sb1_title">联系我们</div>
                        <div class="sb3_cont">

                            <div class="join_us en"><span class="cn">投诉建议</span> : advice@rossoarts.com</div>
                            <div class=" en"><span class="cn">加入我们</span> : hr@rossoarts.com</div>
                            <div class="join_us en"><span class="cn">合作邮箱</span> : mk@rossoarts.com</div>
                            <div class="en"><span class="cn">合作微信</span> : ROSSOMKT</div>

                        </div>
                    </div>

                    <div class="logo_box">
                        <img src="./img/logo1.png" alt="">

                        <!-- <img src="./img/Group 239.png" alt=""> -->
                    </div>
                    <div style="font-size: .17rem;margin: .2rem auto;text-align: center;padding: 0 0 .1rem;">
                Copyright © 2020-2024 上海洛素文化艺术有限公司, All Rights Reserved.
                </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios"
import "@/assets/css/homeold.css"
export default {
    data() {
        return {
            li1: ["上海徐汇区文定路208号A座", "北京市朝阳区百子湾路32号院苹果社区北区6号楼88号", "成都锦江区成都国际金融中心IFS一号楼", "广州市天河区中信广场", "深圳福田区金田路东福华路北岗厦皇庭大厦"],
            li2: ["上海徐汇区文定路208号A座", "北京市朝阳区百子湾路32号院苹果社区北区6号楼88号", "北京市海淀区丹棱街6号丹棱SOHO 619", "广州市天河区中信广场", "深圳福田区金田路东福华路北岗厦皇庭大厦", "杭州西湖区学院路77号黄龙万科中心", "南京市秦淮区中山南路1号南京中心20楼", "成都锦江区成都国际金融中心IFS一号楼", "武汉武昌区中北路9号长城汇", "陕西省西安市碑林区南门外南关正街88号长安国际中心C座507", "东京都新宿区2丁目"],
            li3: [
                "橘郡国际学校北京校区：北京市海淀区苏州街29号北京八一学校西门",
                "深圳市美中学校RoSSo国际部：广东省深圳市龙华区环观南路103号",
                "合肥市协和双语学校RoSSo艺术中心：合肥市滨湖新区嘉陵江路666号",
                "橘郡国际学校呼市校区：内蒙古呼和浩特新城区囫囵南路1号呼市二中国际部",
            ],
            titleCn: "首页",
            iii: 0,
            iii2: 0,
            iii3: 0,
            flag: false,
            formLabelAlign: {
                name: '',
                tel: '',
                region: '',
                region2: '',
                major: '',
                email: '',
            },
            labelPosition: 'right',
            locationData: ["上海徐汇区文定路208号A座", "朝阳区百子湾路32号院苹果社区北区6号楼88号", "广州市天河区中信广场7308室", "成都锦江区成都国际金融中心IFS一号楼"],
            locationData2: ["上海徐汇区文定路208号A座", "朝阳区百子湾路32号院苹果社区北区6号楼88号", "广州市天河区中信广场7308室", "深圳福田区金田路东福华路北岗厦皇庭大厦", "杭州西湖区学院路77号黄龙万科中心", "南京秦淮区汉中路1号南京国际金融中心", "成都锦江区成都国际金融中心IFS一号楼", "武汉武昌区中北路9号长城汇", "西安市碑林区南门外南关正街88号长安国际中心C座507", "东京都新宿区2丁目"],
        }
    },
    created() {
        // alert("hello wlh")
    },
    mounted() {
        // this.setIntervalBot1()
    },
    methods: {
        toggleLocation(val) {
            var newBox_li = document.getElementsByClassName("newBox_li")
            newBox_juti.innerHTML = this.locationData[val]
            for (var i = 0; i < newBox_li.length; i++) {
                newBox_li[i].style.color = "#999"
                newBox_li[i].style.borderRightColor = "#999"
            }
            newBox_li[val].style.color = "#2ca6e0"
            newBox_li[val].style.borderRightColor = "#2ca6e0"
        },
        toggleLocation2(val) {
            var newBox_li = document.getElementsByClassName("newBox_li2")
            newBox_juti2.innerHTML = this.locationData2[val]
            for (var i = 0; i < newBox_li.length; i++) {
                newBox_li[i].style.color = "#999"
                newBox_li[i].style.borderRightColor = "#999"
            }
            newBox_li[val].style.color = "#2ca6e0"
            newBox_li[val].style.borderRightColor = "#2ca6e0"
        },
        hidNavList() {
            this.selctMenu()
        },
        selctMenu() {
            menu.classList.toggle("selected")
            nav_box.classList.toggle("hiddened")
        },
        goHome() {
            this.$router.push("/")
        },
        call() {
            window.event.cancelBubble = true;
        },
        onSubmit() {
            var currentURL = window.location.href;
            var status = ''
            // 检查 URL 中是否包含某些关键词，来判断使用的搜索引擎
            if (currentURL.includes("google.com")) {
                status = "用户正在使用 Google 搜索引擎"
            } else if (currentURL.includes("bing.com")) {
                status = "用户正在使用 Bing 搜索引擎"
            } else if (currentURL.includes("yahoo.com")) {
                status = "用户正在使用 Yahoo 搜索引擎"
            } else if (currentURL.includes("baidu.com")) {
                status = "用户正在使用 百度 搜索引擎"
            } else {
                status = "无法确定用户使用的搜索引擎"
            }
            var patt = /^1[3-9][0-9]{9}$/

            if (this.formLabelAlign.name == "" || this.formLabelAlign.tel == "") {
                // console.log("姓名和手机号不能为空");
                this.$message.error('姓名和手机号不能为空');

            } else {
                // console.log("不是空");

                if (patt.test(this.formLabelAlign.tel)) {

                    // console.log("可以发请求");
                    // console.log(this.formInline);
                    axios.post("https://public.rossoarts.com/index/common/getform", {
                        "name": this.formLabelAlign.name,
                        "tel": this.formLabelAlign.tel,
                        "major": this.formLabelAlign.major,
                        // "email": this.formLabelAlign.email,
                        "url": window.location.href,
                        "status": status,
                    }).then((data) => {
                        console.log(data, "response");
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                    }).catch(function (error) {
                        console.log(error, "error");
                    });

                } else {
                    console.log("手机号格式不正确");
                    this.$message.error('手机号格式不正确');

                }

            }
        },
        zhankaiCourse(e) {
            e.stopPropagation()
            e.preventDefault()
            this.flag = !this.flag
            var course_icon = document.getElementsByClassName("course_icon")
            var course_box = document.getElementsByClassName("course_box")
            if (this.flag) {
                course_box[0].style.height = "4.9rem"
                course_icon[0].style.transform = "rotate(45deg)"
            } else {
                course_box[0].style.height = "0"
                course_icon[0].style.transform = "rotate(-135deg)"
            }
            console.log("111");

        },
        setIntervalBot1() {
            var li3 = document.getElementsByClassName("li3")
            var li2 = document.getElementsByClassName("li2")
            var li1 = document.getElementsByClassName("li1")


            setInterval(() => {
                li3[this.iii3].click()
                li2[this.iii].click()
                li1[this.iii2].click()
                this.iii++
                this.iii2++
                this.iii3++

                if (this.iii == li2.length) {
                    this.iii = 0
                }
                if (this.iii2 == li1.length) {
                    this.iii2 = 0
                }
                if (this.iii3 == li3.length) {
                    this.iii3 = 0
                }
                // console.log(this.iii);

            }, 3000)
            // clearTimeout(timer)


        },
        hidBlack() {
            if (coverBlack.style.display == "block") {
                coverBlack.style.display = "none"
            } else {
                coverBlack.style.display = "block"
            }
        },
        hidCover() {
            cover.style.display = "none"
            document.body.style.overflow = "auto";
        },
        hidBlack2() {
            if (coverBlack2.style.display == "block") {
                coverBlack2.style.display = "none"
            } else {
                coverBlack2.style.display = "block"
            }
        },
        eCoverBlack2(e) {
            e.stopPropagation()
        },

        hidBlack3() {
            if (coverBlack3.style.display == "block") {
                coverBlack3.style.display = "none"
            } else {
                coverBlack3.style.display = "block"
            }
        },
        eCoverBlack3(e) {
            e.stopPropagation()
        },


        showCover() {
            cover.style.display = "block"
            document.body.style.overflow = "hidden";
        },
        mouse2(val) {
            console.log(val);
        },
        mouse3(val) {
            this.iii3 = val
            // console.log(val);
            show3.innerHTML = this.li3[val]
            var lis3 = document.getElementsByClassName("li3")
            if (val == 1) {
                show4.style.display = "block"
            } else {
                show4.style.display = "none"
            }

            for (var i = 0; i < lis3.length; i++) {
                lis3[i].style.fontWeight = "normal"
            }
            lis3[val].style.fontWeight = "bold"

        },
        mouse2(val) {
            this.iii = val
            // console.log(val);
            show2.innerHTML = this.li2[val]
            var lis2 = document.getElementsByClassName("li2")

            for (var i = 0; i < lis2.length; i++) {
                lis2[i].style.fontWeight = "normal"
            }
            lis2[val].style.fontWeight = "bold"

        },
        mouse1(val) {
            this.iii2 = val
            show1.innerHTML = this.li1[val]
            var lis = document.getElementsByClassName("li1")

            for (var i = 0; i < lis.length; i++) {
                lis[i].style.fontWeight = "normal"
            }
            lis[val].style.fontWeight = "bold"
            // liFirst.style.color = "#999"
        },
    }
}

</script>

<style lang="less">
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    letter-spacing: .01rem;
    font-family: "Microsoft YaHei", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB", "Microsoft Yahei", sans-serif, "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial, sans-serif;
}



@font-face {
    font-family: rossoNoto;
    src: url(./img/rossoNoto.ttf);
}

// .cn {
//   font-family: rossoYahei;
// }

.en {
    font-family: rossoNoto;
}

html {
    font-size: 13.333vw;
}

#app {
    // width: 750px;
    background-color: #fff7e9;
    // background-color: #fff;
    // overflow: hidden;
}

#cover {

    background-color: rgba(0, 0, 0, 0.4);
    // opacity: 0.4;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;
    display: none;

    .cover_cont {
        width: 3.6rem;
        // height: 5.73rem;
        border: .02rem solid #000;
        opacity: 1;
        background-color: #fff;
        position: absolute;
        right: .4rem;
        top: .4rem;

        // transform: translateY(-50%);
        .cover_nav_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: .88rem;
            border-top: .02rem solid #000;
            text-align: center;



        }

        .course_main {
            // width: 80%;
            // height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: .88rem;
            border-top: .02rem solid #000;
            text-align: center;
            position: relative;

            .course_icon {
                width: .2rem;
                // background-color: red;
                height: .2rem;
                border-top: .02rem solid #000;
                border-left: .02rem solid #000;
                position: absolute;
                right: .36rem;
                top: calc(50% - .18rem);
                transform: rotate(-135deg);
                z-index: 10;
                transition: all .36s;
                transform-origin: 50%;
            }
        }

        .course_box {
            width: 100%;
            height: 0;
            // background-color: red;
            transition: all .36s;
            overflow: hidden;

            a {
                text-align: center;
                height: .6rem;
                line-height: .6rem;
                font-size: .22rem;
                text-decoration: underline;
                display: block;
            }
        }

        .cover_top {
            width: 100%;
            height: .48rem;
            line-height: .48rem;
            display: flex;
            flex-direction: row-reverse;

            .cover_cha {
                width: .48rem;
                height: .48rem;
                text-align: center;
                border-left: .02rem solid #000;

                font-size: .36rem;
                background-color: #FB6333;
            }
        }
    }

    .cover_cn {
        font-size: .26rem;
    }

    .cover_en {
        font-size: .14rem;
    }
}



// .li2{
//   line-height: .3rem;
// }
.section_bot {
    width: 100%;
    // height: 8rem;
    background-color: #F5E8D2;
    // border: .01rem solid orangered;
    overflow: hidden;

    //  padding-bottom: 1.2rem;
    .sb_bgc {
        height: 100%;
        background-image: url(./img/底图.png);
        background-position: 50% 100%;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .sb_cont {
        width: 7rem;
        // height: 4rem;
        // border: .01rem solid red;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        // justify-content: space-between;
        // align-items: flex-start;
        margin: 0 auto;
        padding-bottom: 1.2rem;


    }

    .logo_box {
        width: 2.83rem;
        display: flex;
        // justify-content: space-between;
        margin-top: .4rem;
        margin-left: .34rem;

        img {
            width: 1.2rem;
            height: 1.5rem;
            margin-right: .34rem;
        }
    }

    .sb1 {
        width: 6.8rem;
        // border: .01rem solid #000;

        .sb1_location {
            // font-family: rossoYahei;
            font-weight: bold;
            color: #000000;
            line-height: .22rem;
            font-size: .2rem;
            height: .41rem;
            text-decoration: underline;
        }

        .sb1_cont {
            font-size: .2rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // margin: .2rem 0 .2rem -0.08rem;
            margin-top: .2rem;

            div {
                width: 1.2rem;
                // margin: 0 .3rem 0 0;
                margin-bottom: .2rem;
                // border: 1px solid #000;
            }
        }

        .sb1_title {
            // width: 3.3rem;
            height: .4rem;
            border-bottom: .03rem solid #5F9DF7;
            line-height: .4rem;
            // border: .01rem solid #000;
            // font-family: rossoYahei;
            font-weight: bold;
            color: #5F9DF7;
            font-size: .2rem;
        }
    }

    .sb3 {
        width: 3.6rem;

        .sb3_cont {
            font-size: .2rem;

            .join_us {
                margin-top: .2rem;
            }

            div {
                line-height: .25rem;
            }

            span {
                font-weight: bold;
            }
        }
    }

    .section_box {
        margin-top: .6rem;
    }

    .sb_title_img {
        width: 3.54rem;
        height: .59rem;
        // border: .01rem solid #000;
        margin: .3rem auto;
        display: block;
    }
}


nav {

    width: 100%;
    height: 1.34rem;
    // border: 1px solid #000;
    background-image: url(./img/Group\ 300.png);
    background-position: 50% 100%;
    background-size: 100%;
    position: relative;
    box-shadow: 0rem .04rem .04rem 0rem rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 100;
    top: 0;

    .nav_right {
        // width: 2.25rem;
        height: .6rem;
        // border: .01rem solid #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: .37rem;
        right: .4rem;

        img {
            width: .48rem;
            height: .48rem;
            // border: .01rem solid #000;
        }

        .nav_text_box {
            // width: 1.21rem;
            height: .6rem;
            text-align: right;
            margin-right: .4rem;

            .nav_client_cn {
                display: block;
                height: .33rem;
                font-size: .26rem;
                // font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: .3rem;

            }

            .nav_client_en {
                height: .17rem;
                font-size: .14rem;
                // font-family: Noto Sans-Bold, Noto Sans;
                font-weight: bold;
                color: #FFFFFF;
                line-height: .16rem;
                display: block;
                white-space: nowrap;
            }
        }

    }

    .nav_left {
        width: 2.4rem;
        height: .8rem;
        // border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        flex-wrap: wrap;
        position: absolute;
        bottom: .4rem;
        left: .4rem;

        p {
            width: 1.8rem;
            font-size: .24rem;
            font-weight: bold;
            color: #fff;
            white-space: nowrap;

            span {
                font-size: .16rem;
                margin-left: .05rem;
            }
        }

        .icon_nmb_box {
            width: 1.8rem;
            height: .28rem;
            // border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon_nmb {
                width: .17rem;
                height: .18rem;
                line-height: .18rem;

                // border: 1px solid #000;
            }

            .numb {
                font-size: .22rem;
                margin-left: .05rem;
                white-space: nowrap;
            }
        }

        .nav_logo {
            // width: .42rem;
            height: .53rem;
            // border: 1px solid #000;
            margin: 0 .1rem .05rem 0;
        }
    }




    a {
        font-weight: 400;
        color: #000;

        &.router-link-exact-active {
            color: #000;
            font-weight: bold;
            // background-color: #5F9DF7;
        }
    }
}


.nav_bottom {
    // border: .01rem solid #000;
    width: 100%;
    position: fixed;
    top: 1.34rem;
    z-index: 99;
    height: .48rem;
    margin-top: -.01rem;
    font-size: .2rem;
    display: flex;
    justify-content: center;
    // background-color: #fff7e9;

    .nav_bottom_item {
        width: 1.63rem;
        height: .48rem;
        // border: .01rem solid #000;
        text-align: center;
        line-height: .49rem;
        background-position: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
        color: #fff;
        font-weight: bold;




    }

    .nav_bottom_item:nth-of-type(1) {
        background-size: 101%;
        background-image: url(./img/Rectangle\ 507.png);
    }

    .nav_bottom_item:nth-of-type(2) {
        background-color: #2A78E8;
    }

    .nav_bottom_item:nth-of-type(3) {
        background-color: #4C94FD;
    }

    .nav_bottom_item:nth-of-type(4) {
        background-size: 101%;
        background-image: url(./img/Rectangle\ 508.png);
    }

}

#coverBlack {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 10000;
    top: 0;
    display: none;

    #coverMainImg {
        width: 50%;
        position: absolute;
        // border: 10px solid red;
        left: calc(50%);
        top: calc(50%);
        transform: translate(-50%, -50%);

    }
}


#coverBlack2 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    //   z-index: 9999200;
    z-index: 200;
    top: 0;
    display: none;
    //   .el-select-dropdown .el-popper{
    //     z-index: 99999300!important;
    //   }

    .cover_inp_box {
        border-radius: .2rem;
        overflow: hidden;
        width: 5.2rem;
        height: 6rem;
        border: .01rem solid #000;
        background-color: #fff;
        position: absolute;
        top: calc(50% - 3rem);
        left: calc(50% - 2.6rem);
        display: flex;
        flex-direction: column;

        .cover_inp_left {
            width: 5.2rem;
            height: 1.46rem;
        }

        .hidCha {
            width: .4rem;
            height: .4rem;
            border-radius: 50%;
            background: #D2A96D;
            position: absolute;
            cursor: pointer;
            margin-right: .05rem;
            right: .16rem;
            top: .16rem;

            .p3 {
                width: .2rem;
                height: .02rem;
                background-color: #fff;
                position: absolute;
                top: calc(50% - .01rem);
                left: calc(50% - .1rem);
                transform: rotate(45deg);
                transform-origin: 50%;
            }

            .p4 {
                width: .2rem;
                height: .02rem;
                background-color: #fff;
                position: absolute;
                top: calc(50% - .01rem);
                left: calc(50% - .1rem);
                transform: rotate(-45deg);
                transform-origin: 50%;
            }
        }

        .cover_inp_right {
            width: 100%;
            margin-top: .4rem;

            .s_inp_right {
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .el-form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .el-form-item {
                        height: .48rem;
                        // border: .01rem solid #000;
                        display: flex;
                        align-items: center;
                        margin-right: 0;
                        margin-bottom: .24rem;

                        .el-form-item__label {
                            font-size: .22rem;
                            font-weight: bold;
                            color: #000;
                            line-height: .4rem;
                            padding: 0 .12rem 0 0;
                        }

                        .el-input__suffix-inner {
                            height: 100%;
                            display: flex;
                            // border: 1px solid #000;
                            // vertical-align: middle;
                        }

                        .el-select__caret {
                            height: 100%;
                            // border: 1px solid red;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                        }

                        .el-input {
                            width: 2.78rem;
                            height: .48rem;
                            border: .01rem solid #000;
                            border-radius: .25rem;
                            display: flex;
                            align-items: center;
                            background: #FBF3E5;

                            .el-input__inner {
                                border: none;
                                border-radius: .25rem;
                                background: #FBF3E5;
                                text-align: center;
                                font-size: .22rem;
                                line-height: .4rem;
                                height: .4rem;
                            }
                        }
                    }
                }

                .el-form-item__content {
                    margin: 0 auto;
                }

                .yuyue {
                    width: 4.06rem;
                    height: .44rem;
                    border: .02rem solid #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: .25rem;
                    background-color: #fb6333;
                    margin-top: .1rem;

                    img {
                        width: .28rem;
                        height: .28rem;
                    }

                    p {

                        font-size: .22rem;
                        font-weight: bold;
                        color: #fff;
                        margin-left: .1rem;
                    }
                }
            }
        }
    }
}

#coverBlack3 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    //   z-index: 9999200;
    z-index: 200;
    top: 0;
    display: none;
    //   .el-select-dropdown .el-popper{
    //     z-index: 99999300!important;
    //   }

    .cover_inp_box {
        border-radius: .2rem;
        overflow: hidden;
        width: 5.2rem;
        // height: 6rem;
        padding-bottom: .2rem;
        // border: .01rem solid #000;
        background-color: #fff;
        position: absolute;
        top: calc(50% - 3rem);
        left: calc(50% - 2.6rem);
        display: flex;
        flex-direction: column;

        .cover_inp_left {
            width: 5.2rem;
            height: 1.46rem;
        }

        .hidCha {
            width: .4rem;
            height: .4rem;
            border-radius: 50%;
            background: #D2A96D;
            position: absolute;
            cursor: pointer;
            margin-right: .05rem;
            right: .16rem;
            top: .16rem;

            .p3 {
                width: .2rem;
                height: .02rem;
                background-color: #fff;
                position: absolute;
                top: calc(50% - .01rem);
                left: calc(50% - .1rem);
                transform: rotate(45deg);
                transform-origin: 50%;
            }

            .p4 {
                width: .2rem;
                height: .02rem;
                background-color: #fff;
                position: absolute;
                top: calc(50% - .01rem);
                left: calc(50% - .1rem);
                transform: rotate(-45deg);
                transform-origin: 50%;
            }
        }

        .cover_inp_right {
            width: 100%;
            margin-top: .4rem;

            .s_inp_right {
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .el-form {

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .el-form-item {
                        width: 4rem !important;
                        height: .48rem;
                        // border: .01rem solid #000;
                        display: flex;
                        align-items: center;
                        margin-right: 0;
                        margin-bottom: .24rem;

                        .el-form-item__label {
                            text-align: left;
                            font-size: .22rem;
                            font-weight: bold;
                            color: #000;
                            line-height: .4rem;
                            padding: 0 .12rem 0 0;
                        }

                        .el-input__suffix-inner {
                            height: 100%;
                            display: flex;
                            // border: 1px solid #000;
                            // vertical-align: middle;
                        }

                        .el-select__caret {
                            height: 100%;
                            // border: 1px solid red;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                        }

                        .el-input {
                            width: 3.15rem;
                            height: .48rem;
                            border: .01rem solid #000;
                            border-radius: .25rem;
                            display: flex;
                            align-items: center;
                            background: #FBF3E5;

                            .el-input__inner {
                                border: none;
                                border-radius: .25rem;
                                background: #FBF3E5;
                                text-align: center;
                                font-size: .22rem;
                                line-height: .4rem;
                                height: .4rem;
                            }
                        }
                    }
                }

                .el-form-item__content {
                    margin: 0 auto;
                }

                .yuyue {
                    width: 4rem;
                    height: .44rem;
                    border: .02rem solid #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: .25rem;
                    background-color: #fb6333;
                    margin-top: .1rem;

                    img {
                        width: .28rem;
                        height: .28rem;
                    }

                    p {

                        font-size: .22rem;
                        font-weight: bold;
                        color: #fff;
                        margin-left: .1rem;
                    }
                }
            }
        }
    }
}





.yuyue {
    margin-top: .1rem;
}

#newcefu {
    display: none;
}

// old ????????????????????
.newFooter {
    background-color: #393939;
    padding-top: .01rem;
    padding-bottom: .6rem;

    .newTop {
        width: 6.9rem;
        height: 1.15rem;
        border-bottom: .01rem solid hsla(0, 0%, 100%, .1);
        margin: .3rem auto 0;
        display: flex;
        justify-content: space-between;

        .newTitle {
            // width: 3.22rem;
            color: #fff;

            // border: .01rem solid #000;
            .title {
                width: 3.32rem;
                font-size: .32rem;
            }

            .text {
                font-size: .2rem;
                margin-top: .1rem;
            }
        }

        .newRight {
            width: 3.2rem;
            display: flex;
            align-items: center;

            img {
                width: .22rem;
                height: .22rem;
            }

            p {
                font-size: .2rem;
                display: flex;
                align-items: center;
                color: #2ca6e0;

                span {
                    font-size: .22rem;
                    font-weight: bold;
                }
            }

        }
    }

    .newBox {
        width: 6.9rem;
        margin: .6rem auto;

        .newBox_title {
            font-size: .32rem;
            color: #ccc;
        }

        .newBox_ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: .46rem;

            .newBox_li {
                padding: 0 .48rem;
                font-size: .28rem;
                color: #999;
                margin-bottom: .46rem;
                // border-left: .02rem solid #999;
                border-right: .02rem solid #999;
            }

            .newBox_li:nth-of-type(1) {
                padding-left: 0;
                color: #2ca6e0;
                // border-left:none;
                border-right-color: #2ca6e0;
            }

            .newBox_li2 {
                padding: 0 .48rem;
                font-size: .28rem;
                color: #999;
                margin-bottom: .46rem;
                // border-left: .02rem solid #999;
                border-right: .02rem solid #999;
            }

            .newBox_li2:nth-of-type(1) {
                padding-left: 0;
                color: #2ca6e0;
                // border-left:none;
                border-right-color: #2ca6e0;
            }

            .newBox_li2:nth-of-type(5) {
                padding-left: 0;

            }
        }

        .newBox_juti {

            display: flex;
            align-items: center;
            margin-bottom: .6rem;

            img {
                width: .24rem;
                height: .27rem;
                margin-right: .1rem;
            }
        }

        #newBox_juti {
            font-size: .28rem;
            color: #2ca6e0;
        }

        #newBox_juti2 {
            font-size: .28rem;
            color: #2ca6e0;
        }

        .newSa {
            margin-top: 1rem;

            p {
                height: .4rem;
                line-height: .4rem;
                font-size: .2rem;
                color: #999;
            }
        }
    }
}
</style>
